@keyframes pulseAnimation {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .animate-pulse-custom {
    animation: pulseAnimation 3s infinite ease-in-out;
  }